import ajax from "@/utils/ajax.js";

/**
 * 支付通道：列表
 * @param {*} params
 * @returns
 */
export function getPaymentList(params) {
  return ajax.post("/PayConfig/getList", params);
}

/**
 * 支付通道：详情
 * @param {*} params
 * @returns
 */
export function getPaymentDetail(params) {
  return ajax.post("/PayConfig/detail", params);
}

/**
 * 支付通道：添加
 * @param {*} params
 * @returns
 */
export function addPayment(params) {
  return ajax.post("/PayConfig/add", params);
}

/**
 * 支付通道：修改
 * @param {*} params
 * @returns
 */
export function updatePayment(params) {
  return ajax.post("/PayConfig/update", params);
}

