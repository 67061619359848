<template>
  <a-card>
    <a-form-model :model="formData"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">

      <!-- 名字 -->
      <a-form-model-item label="网点名称"
                         class="mb-2"
                         prop="name"
                         :rules="requiredRule"
                         :maxLength="40">
        <a-input v-model="formData.name"
                 placeholder="请输入网点名称" />
      </a-form-model-item>

      <a-form-model-item label="门店类型"
                         class="mb-2"
                         prop="outlets_type"
                         :rules="requiredRule">
        <a-radio-group v-model="formData.outlets_type" @change="changeOutletsType"
                       button-style="solid"
                       :disabled="Boolean($route.query.outletsID)">
          <a-radio-button :value="2">配送站</a-radio-button>
          <a-radio-button :value="1">门店</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-divider />
      <!-- 位置 -->
      <a-form-model-item label="地图位置"
                         class="mb-3"
                         :wrapperCol="wrapperCol"
                         :rules="requiredRule">
        <div class="map-container">
          <div class="flex justify-between items-center">
            <span class="mr-2" style="width: 100px;">输入地址定位:</span>
            <div class="flex" style="flex:1;">
              <a-select style="flex:1;" placeholder="输入地址可以快速定位经纬度"
                        show-search
                        v-model="latlngAddress"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="handleSearch"
                        @change="handleChange">
                <a-select-option v-for="d in addressList"
                                 :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
              </a-select>
              <a-input v-model="formData.longitude" class="ml-2" style="width: 120px;" placeholder="经度" />
              <a-input v-model="formData.latitude" class="ml-2" style="width: 120px" placeholder="纬度" />
            </div>
          </div>
          <div class="flex">
            <span class="mr-2" style="width: 100px;">网点所在区域:</span>
            <div class="flex" style="flex:1;">
              <a-select class="mr-2" placeholder="请选择城市"
                        @change="handlerCityChange" v-model="formData.adcode">
                <!-- <a-select-option key="" value="">城市</a-select-option> -->
                <a-select-option v-for="(item, index) in cityList"
                                 :key="index" :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
              <a-select class="mr-2" placeholder="请选择区/县"
                        @change="handlerAreaChange" v-model="formData.area_adcode">
                <!-- <a-select-option key="" value="">区/县</a-select-option> -->
                <a-select-option v-for="(item, index) in areaList"
                                 :key="index" :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
              <a-select placeholder="请选择街道"
                        @change="handlerTreeChange" v-model="formData.street_adcode">
                <!-- <a-select-option key="" value="">街道/乡镇</a-select-option> -->
                <a-select-option v-for="(item, index) in streetList"
                                 :key="index" :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
            </div>
              
          </div>
          <div id="shop_map_container" class="map-class"></div>
        </div>
      </a-form-model-item>

      <a-form-model-item label="完整地址"
                         class="mb-2"
                         prop="address"
                         :rules="requiredRule">
        <a-input v-model="formData.address"
                 placeholder="输入完整地址（用户端显示）" />
      </a-form-model-item>

      <a-divider />
      <!-- 经营信息 -->

      <!--  1=门店 需要选择时间 2=配送站 不用选择时间 -->
      <a-form-model-item v-if="formData.outlets_type === 1"
                         class="mb-2"
                         label="营业时间"
                         :rules="requiredRule">
        <a-time-picker style="margin-right: 10px"
                       format="HH:mm"
                       valueFormat="HH:mm"
                       v-model="formData.business_hours_start" />至
        <a-time-picker style="margin-left: 10px"
                       format="HH:mm"
                       v-model="formData.business_hours_end"
                       valueFormat="HH:mm" />
      </a-form-model-item>
      <!--  1. 不可配送 2. 可配送 -->
      <a-form-model-item label="配送能力"
                         :rules="requiredRule"
                         class="mb-2">
        <a-checkbox @change="handlerPeisong" :disabled="formData.outlets_type == 2"
                    :checked="formData.is_delivery === 2">可配送</a-checkbox>
        <template v-if="formData.is_delivery === 2">
            <span class="ml-8">最大运力：</span>
            <a-select default-value="1"
                      style="width:80px"
                      :disabled="formData.is_delivery === 1"
                      v-model="formData.delivery_limit">
              <a-select-option :value="index + 1"
                               v-for="(item, index) in 150"
                               :key="index">{{ index + 1 }}</a-select-option>
            </a-select>
            <!--  如果可以配送 就选择配送时间 -->
            <span class="ml-6">配送时间：</span>
            <a-time-picker class="mr-2" style="width:110px;"
                           valueFormat="HH:mm"
                           format="HH:mm"
                           v-model="formData.delivery_hours_start"
                           :disabled="formData.is_delivery != 2" />至
            <a-time-picker class="ml-2" style="width:110px;"
                           format="HH:mm"
                           valueFormat="HH:mm"
                           :default-open-value="moment('00:00:00', 'HH:mm')"
                           v-model="formData.delivery_hours_end"
                           :disabled="formData.is_delivery != 2" />
        </template>
          
      </a-form-model-item>

      <a-form-model-item v-if="formData.outlets_id && formData.outlets_type == 1" 
                         label="备货时间" prop="prepare_time"
                         class="mb-2">
        <span>{{formData.prepare_time}}分钟</span>
      </a-form-model-item>

      <a-form-model-item label="网点电话"
                         prop="tel"
                         class="mb-2"
                         :rules="requiredRule">
        <a-input v-model="formData.tel"
                 placeholder="请输入网点电话" />
      </a-form-model-item>
      <a-form-model-item label="负责人"
                         class="mb-2"
                         prop="principal"
                         :rules="requiredRule">
        <span class="flex">
          <a-input v-model="formData.principal"
                   placeholder="负责人姓名" />
          <a-input v-model="formData.principal_phone"
                   class="ml-2"
                   placeholder="负责人的联系电话"></a-input>
        </span>
      </a-form-model-item>
      <a-form-model-item label="售价区域"
                         class="mb-2"
                         prop="regional_pricing_id"
                         :rules="requiredRule">
        <!-- <a-select placeholder="请选择售价区域"
                  v-model="formData.regional_pricing_id">
          <a-select-option v-for="(item, index) in priceAreaList"
                           :key="index"
                           :value="item.id">{{ item.name }}</a-select-option>
        </a-select> -->

        <a-select placeholder="请选择售价区域"
                  v-model="formData.regional_pricing_id" 
                  show-search
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handlerSuggestPriceArea">
          <a-select-option v-for="item in suggestPriceAreaList"
                           :key="item.id"
                           :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-divider />

      <a-form-model-item label="城市市场"
                         class="mb-2"
                         prop="outlets_city_adcode"
                         :rules="requiredRule">
        <a-select placeholder="选择归归属的城市地区"
                  v-model="formData.outlets_city_adcode" 
                  show-search
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handlerSuggestManageCity"
                  @change="onChangeBelongCity">
          <a-select-option v-for="item in suggestManageCityList"
                           :key="item.adcode"
                           :value="Number(item.adcode)">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <div>
        <a-form-model-item label="财务账户"
                           class="mb-2"
                           prop="financial_account_id"
                           :rules="requiredRule">
          <div class="flex">
            <div class="mr-4" style="flex:2;">
              <a-select placeholder="请选择财务账户" @change="onChangeFinance"
                        v-model="formData.financial_account_id">
                <a-select-option v-for="(item, index) in accountList"
                                 :key="index"
                                 :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </div>
            <div style="flex:3;">
              <div class="flex items-center">
                <span style="width:130px;text-align:left;" class="mr-2">收款支付配置：</span>
                <a-radio-group v-model="isCustomPay" @change="onChangePayment" 
                                 button-style="solid">
                    <a-radio-button :value="false">默认收款配置</a-radio-button>
                    <a-radio-button :value="true">自定义收款</a-radio-button>
                </a-radio-group>
              </div>
                
              <div v-if="isCustomPay">
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">微信商户号-小程序:</span>
                  <!-- <span style="width:90px;text-align:right;" class="mr-2">微信-小程序:</span> -->
                  <a-select placeholder="选择收款商户号" class="mr-4" style="flex:1;"
                            v-model="formData.weixin_pay_config_id">
                    <a-select-option v-for="(item, index) in paymentList_wx1"
                                     :key="index" :value="item.id"
                      >{{ item.name }} <span style="color:#ccc;">{{item.weixin_merchant_id}}</span></a-select-option>
                  </a-select>
                </div>
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">微信商户号-网站:</span>
                  <!-- <span style="width:90px;text-align:right;" class="mr-2">微信-网站:</span> -->
                  <a-select placeholder="选择收款商户号" class="mr-4" style="flex:1;"
                            v-model="formData.weixin_2_pay_config_id">
                    <a-select-option v-for="(item, index) in paymentList_wx2"
                                     :key="index" :value="item.id"
                      >{{ item.name }} <span style="color:#ccc;">{{item.weixin_merchant_id}}</span></a-select-option>
                  </a-select>
                </div>
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">支付宝AppId:</span>
                  <!-- <span style="width:90px;text-align:right;" class="mr-2">支付宝:</span> -->
                  <a-select placeholder="选择AppId" class="mr-4" style="flex:1;"
                            v-model="formData.alipay_pay_config_id">
                    <a-select-option v-for="(item, index) in paymentList_ali"
                                     :key="index" :value="item.id"
                      >{{ item.name }} <span style="color:#ccc;">{{item.alipay_app_id}}</span></a-select-option>
                  </a-select>
                </div>
              </div>
              <div v-else>
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">微信商户号-小程序:</span>
                  <a-input v-model="financeInfo.weixin_merchant_id" style="flex:1;" disabled></a-input>
                </div>
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">微信商户号-网站:</span>
                  <a-input v-model="financeInfo.weixin_merchant_id_2" style="flex:1;" disabled></a-input>
                </div>
                <div class="flex items-center">
                  <span style="width:130px;text-align:left;" class="mr-2">支付宝AppId:</span>
                  <a-input v-model="financeInfo.alipay_app_id" style="flex:1;" disabled></a-input>
                </div>
              </div>
            </div>
          </div>
            
        </a-form-model-item>
      </div>
      

      <a-divider />
      <!-- 收银软件、门店码 -->
      <a-form-model-item label="收银系统"
                         class="mb-2"
                         prop="offline_cash_register_id"
                         :rules="requiredRule"
                         :maxLength="40">
        <div class="flex"
             style="align-items: center;">
          <a-select placeholder="收银系统"
                    style="width:200px;"
                    v-model="formData.offline_cash_register_id">
            <a-select-option key="0"
                             :value="Number(0)">无</a-select-option>
            <a-select-option v-for="(item, index) in cashierList"
                             :key="index+1"
                             :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </div>
      </a-form-model-item>
      <!-- 收银软件、门店码 -->
      <a-form-model-item label="城市门店编码"
                         class="mb-2"
                         prop="outlets_code"
                         :rules="requiredRule"
                         :maxLength="40">
        <div class="flex justify-between"
             style="width: 530px;align-items: center;">
          <span class="label">收银的城市码：
            <a-input v-model="formData.city_code"
                     placeholder="城市码"
                     style="width:120px;" />
          </span>
          <span class="label" style="width:270px;">收银的门店码：
            <a-input v-model="formData.outlets_code"
                     placeholder="门店码"
                     style="width:145px;"/>
          </span>
        </div>
        <div class="flex justify-between"
             style="width: 530px;align-items: center;">
          <span class="label">实体卡的城市码：
            <a-input v-model="formData.stored_value_card_city_code"
                     placeholder="城市码"
                     style="width:120px;" />
          </span>
          <span class="label" style="width:270px;">实体卡的门店码：
            <a-input v-model="formData.stored_value_card_outlets_code"
                     placeholder="门店码"
                     style="width:145px;"/>
          </span>
        </div>
      </a-form-model-item>

      <a-divider />
      <!-- 保存按钮 -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary"
                  @click="onSubmit">保存</a-button>
        <!-- <a-button style="margin-left: 10px"
                  @click="$router.go(-1)">取消</a-button> -->
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import config from '@/utils/config.js'
import moment from "moment"
import { requiredRule, phoneRule } from "@/hooks/use-form-rules"
import {
  getCityList,
  // getFinanceCityList,
  // getOutletsList,
  getOutletsDetail,
  addOutlet,
  updateOutlet,
} from "@/api/outlets"
import {
  getList as getCashierList,
} from "@/api/system/cashier"
import { getRegionList } from "@/api/util"
import { getAreaList } from "@/api/area-price"
import {
  getList as getAccountList,
  getDetail as getAccountDetail
} from "@/api/system/account"
import {
  getPaymentList,
} from "@/api/trading-manage/pay-account.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'
// import TMap from '@/components/t-map/index'

import { getMapPointList, getAdcode } from "@/utils/tMap.js"

let _mapTimer = null

export default {
  // components: { TMap },
  data () {
    return {
      requiredRule: requiredRule,
      phoneRule: phoneRule,
      shopType: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      latlngAddress: null,
      addressList: [],
      formData: {
        outlets_id: "",
        name: "",
        is_delivery: 1,
        type: 1,
        outlets_type: 2,
        regional_pricing_id: "",
        business_hours_start: "",
        business_hours_end: "",
        delivery_hours_start: "",
        delivery_hours_end: "",
        outlets_city_adcode: "", // 管理城市
        adcode: undefined, // 市
        area_adcode: undefined, // 区县
        street_adcode: undefined, // 街道
        delivery_limit: 0,
        tel: "",
        latitude: "",
        longitude: "",
        address: "",
        // detailAddress: '',
        offline_cash_register_id: 0,

        weixin_pay_config_id: undefined,
        weixin_2_pay_config_id: undefined,
        alipay_pay_config_id: undefined,
      },
      isCustomPay: false,
      financeInfo: {},
      paymentList_wx1: [],
      paymentList_wx2: [],
      paymentList_ali: [],

      cityList: [],
      areaList: [],
      streetList: [],
      priceAreaList: [],
      suggestPriceAreaList: [],
      manageCityList: [],
      suggestManageCityList: [],
      accountList: [],
      cashierList: [],
      isCityManage: isCityManage(),
      editor: null,
      marker: null,
      markerGeo: null,
      map: null,

      loading: false
    }
  },

  async created () {
    this.getCityListData()
    this.getPriceAreaData()
    // this.getAccountList()

    this.manageCityList = await getMarketCityList()
    this.suggestManageCityList = this.manageCityList

    this.getCashierListData()
    if (this.$route.query.outletsID) {
      // 编辑
      this.formData.outlets_id = this.$route.query.outletsID
      if (this.$route.query.outletsID) {
        await this.initData(this.$route.query.outletsID)
        this.getAccountList()
        this.initPaymentList()
      }
    } else {
      // 新增
      this.getLoLa()
    }
  },
  mounted () { 
    this.changeOutletsType()
  },
  watch: {
    'formData.latitude' () {
      this.updatePoi();
    },
    'formData.longitude' () {
      this.updatePoi();
    },
  },
  methods: {
    moment,

    // 对外的城市列表
    async getCityListData () {
      const { code, data } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },
    
    // 收银软件
    async getCashierListData () {
      const { code, data } = await getCashierList()
      if (code == 0) {
        this.cashierList = data.list
      }
    },

    async getAccountList () {
      const { code, data } = await getAccountList({
        belong_outlets_city_adcode: this.formData.outlets_city_adcode || "",
        is_city: 2
      })
      if (code == 0) {
        this.accountList = data.list
      }
    },
    // 售价区域
    async getPriceAreaData () {
      const { code, data } = await getAreaList()
      if (code == 0) {
        this.priceAreaList = data.list
      }
      this.suggestPriceAreaList = this.priceAreaList
    },
    changeOutletsType(){
      if(this.formData.outlets_type == 2){
        this.formData.is_delivery = 2
      }
    },
    // 选择城市列表获取区/县数据
    async handlerCityChange (e) {
      const { code, data } = await getRegionList({ type: 3, parent_adcode: e })
      if (code == 0) {
        this.areaList = data.list
        if(data.list.findIndex(el=>el.adcode == this.formData.area_adcode) == -1){
          this.formData.area_adcode = undefined
          this.formData.street_adcode = undefined
        }
      }
    },
    // 选择区/县列表获取街道数据
    async handlerAreaChange (e) {
      const { code, data } = await getRegionList({ type: 4, parent_adcode: e })
      if (code == 0) {
        this.streetList = data.list
        if(data.list.findIndex(el=>el.adcode == this.formData.street_adcode) == -1){
          this.formData.street_adcode = undefined
        }
      }
    },
    async handlerTreeChange (e) {
      console.log(e)
      // const { code, data } = await getRegionList({ type: 4, parent_adcode: e })
      // if (code == 0) {
      //   this.treeList = data.list
      // }
    },
    async initData (id) {
      const { code, data } = await getOutletsDetail({ outlets_id: id })
      if (code == 0) {
        data.adcode = Number(data.adcode)
        data.outlets_id = id

        // 为了兼容一下 组件的显示
        data.adcode = data.adcode || undefined
        data.area_adcode = data.area_adcode || undefined
        data.street_adcode = data.street_adcode || undefined

        // 经纬度校准（否则地图js就报错了）
        if(data.latitude && data.latitude > 90){
          if(data.latitude > 90 || data.latitude < -90){
            data.latitude  = ""
            data.longitude = ""
          }
        }
        
        this.formData = data
        this.formData.outlets_type = data.type
        

        // this.handlerCityChange(data.outlets_city_adcode)
        this.handlerCityChange(data.adcode)
        this.handlerAreaChange(data.area_adcode)
        
        this.initMapLater(data.latitude, data.longitude)

        this.onChangeFinance()
        // this.initPaymentList()

        if(this.formData.weixin_pay_config_id ||this.formData.weixin_2_pay_config_id ||  this.formData.alipay_pay_config_id){
          this.isCustomPay = true
        }else{
          this.formData.weixin_pay_config_id = undefined
          this.formData.weixin_2_pay_config_id = undefined
          this.formData.alipay_pay_config_id = undefined
        }
      }
    },

    initMapLater(lat, lng){
      console.log("initMapLater", lat, lng)
      if(_mapTimer){
        clearInterval(_mapTimer)
        _mapTimer = null
      }
      let count = 0
      _mapTimer = setInterval(()=>{
        count++
        if(window.TMap){
          count = 10000
          this.initMap(lat, lng)
        }
        if(count > 20){
          clearInterval(_mapTimer)
          _mapTimer = null
        }
      }, 1000)
    },

    handlerPeisong (e) {
      if (e.target.checked) {
        this.formData.is_delivery = 2
      } else {
        this.formData.is_delivery = 1
      }
    },

    // 地址输入
    handleSearch (value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item => {
            item.region = item.province + item.city + item.district
            return item
          })
        }
      })
    },
    async handleChange (value) {
      const item = this.addressList.find((item) => item.id === value)
      // this.formData.name = item.title
      // this.formData.tel = item.tel
      this.latlngAddress = item.address + item.title
      this.formData.address = item.address + item.title
      this.formData.latitude = item.location.lat
      this.formData.longitude = item.location.lng
      // this.formData.outlets_city_adcode = item.adcode.toString().substring(0, 4) + "00000000"
      this.formData.adcode = Number(item.adcode.toString().substring(0, 4) + "00000000")
      this.formData.area_adcode = Number(item.adcode.toString().substring(0, 6) + "000000")

      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        this.formData.street_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
      } else {
        this.formData.street_adcode = undefined
      }

      // this.handlerCityChange(Number(this.formData.outlets_city_adcode))
      this.handlerCityChange(Number(this.formData.adcode))
      this.handlerAreaChange(Number(this.formData.area_adcode))

      // this.updatePoi();
    },

    updatePoi () {
      //修改点标记的位置
      if (this.marker) {
        this.marker.setGeometries([
          {
            "styleId": "marker",
            "id": "marker-1",
            "position": new window.TMap.LatLng(this.formData.latitude, this.formData.longitude),
          }
        ])

        //初始化  将所有的点展示到范围内***********************************************************
        var bounds = new window.TMap.LatLngBounds();
        //设置自适应显示marker
        if (bounds.isEmpty() || !bounds.contains(this.marker.geometries[0].position)) {
          bounds.extend(this.marker.geometries[0].position);
        }
        //设置地图可视范围
        this.map.fitBounds(bounds);
      }
    },

    onSubmit(){
      if(this.loading) return

      if(!this.formData.adcode || !this.formData.area_adcode || !this.formData.street_adcode){
        this.$message.info('请完善省市区县街道信息')
        return
      }

      const _this = this
      this.$confirm({
        title: "确认提交？",
        async onOk() {
          _this.onSubmitOk()
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },

    async onSubmitOk () {
      this.formatPayment()

      if (!this.$route.query.outletsID) {
        // 新增
        this.formData.type = this.formData.outlets_type

        this.loading = true
        const { code, message } = await addOutlet(this.formData)
        this.loading = false
        if (code == 0) {
          this.$message.success(message)
          this.$router.go(-1)
        }
      } else {
        // 编辑
        this.formData.outlets_id = this.$route.query.outletsID
        
        this.loading = true
        const { code, message } = await updateOutlet(this.formData)
        this.loading = false
        if (code == 0) {
          this.$message.success(message)
          this.$router.go(-1)
        }
      }
    },

    onChangeBelongCity(){
      this.getAccountList()
    },

    async onChangeFinance(){
      this.financeInfo = {}
      if(this.formData.financial_account_id){
        const { data } = await getAccountDetail({
          id: this.formData.financial_account_id
        })
        this.financeInfo = data
      }
    },
    async onChangePayment(){
      this.formatPayment()
      this.initPaymentList()
    },
    formatPayment(){
      if(!this.isCustomPay){
        this.formData.weixin_pay_config_id = undefined
        this.formData.weixin_2_pay_config_id = undefined
        this.formData.alipay_pay_config_id = undefined
      }
    },

    initPaymentList(){
      getPaymentList({
        financial_account_belong_outlets_city_adcode: this.formData.outlets_city_adcode || "",
      }).then(res=>{
        const list = res.data.list || []

        this.paymentList_wx1 = list.filter(el=>{
          return el.pay_mode == 1 && el.platform_type == 1
        })

        this.paymentList_wx2 = list.filter(el=>{
          return el.pay_mode == 1 && el.platform_type == 2
        })

        this.paymentList_ali = list.filter(el=>{
          return el.pay_mode == 2
        })
      })
    },
    
    handlerSuggestManageCity(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestManageCityList = this.manageCityList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestManageCityList = this.manageCityList
      }
    },
    handlerSuggestPriceArea(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestPriceAreaList = this.priceAreaList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestPriceAreaList = this.priceAreaList
      }
    },
    
    initMap (la, lo) {
      const _this = this
      this.markerGeo = {
        id: "marker-1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        styleId: "marker", // 指定样式id
        position: new window.TMap.LatLng(la, lo), //点标记坐标位置
      }
      // 设置中心点坐标
      let center = new window.TMap.LatLng(la, lo)

      // 初始化地图
      this.map = new window.TMap.Map(
        document.getElementById("shop_map_container"),
        {
          center: center,
          zoom: 15,
        }
      )

      this.marker = new window.TMap.MultiMarker({
        id: "shop_map_container_layer",
        map: this.map,
        styles: {
          marker: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", //图片路径
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [this.markerGeo],
      })

      // 初始化几何图形及编辑器
      const editor = new window.TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [
          {
            overlay: this.marker, // 可编辑图层
            id: "marker",
            selectedStyleId: "highlight", // 被选中的marker会变为高亮样式
          },
        ],
        actionMode: window.TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        activeOverlayId: "marker", // 激活图层
        selectable: true,
      })

      // 监听marker移动
      editor.on("adjusting", (e) => {
        _this.formData.latitude = e.position.lat
        _this.formData.longitude = e.position.lng
      })

      this.map.on("click", (evt) => {
        // 获取click事件返回的poi信息
        let poi = evt.poi
        if (poi) {
          // 拾取到POI
          _this.formData.address = poi.name
          _this.formData.latitude = poi.latLng.lat
          _this.formData.longitude = poi.latLng.lng
          this.markerGeo.position = poi.latLng
          this.marker.updateGeometries([this.markerGeo])
        } else {
          // 没有拾取到POI
        }
      })
    },
    getLoLa () {
      var geolocation = new window.qq.maps.Geolocation(
        config.MAP_KEY,
        "HLL-ADMIN"
      )
      geolocation.getLocation((res) => {
        this.formData.latitude = res.lat
        this.formData.longitude = res.lng
        this.initMap(res.lat, res.lng)
      }, () => {
        this.initMap(39.908823, 116.39747)
      })

    },
  },
}
</script>

<style lang="less" scoped>
.map-container {
  background-color: #e6e6e6;
  border-radius: 6px;
  width: 100%;
  // height: 500px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  .map-lola {
    height: 35px;
    display: flex;
  }
  .map-class {
    margin-top: 10px;
    width: 100%;
    height: 385px;
  }
}

.addr-region {
  margin-left: 10px;
  color: #ccc;
}
.label{
  width: 240px;
  display: flex;
  justify-content: right;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}
</style>
